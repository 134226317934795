import { Builder, BuilderComponent } from "@builder.io/react"
import { Link } from "gatsby"
import get from "lodash/get"
import * as React from "react"
import Layout from "../layouts/RootLayout"

class p404Index extends React.Component {
  render() {
    const articles = get(this, "props.data.vendure.search.items")
    const collections = get(this, "props.data.vendure.collections.items")
    if (Builder.isPreviewing || Builder.isEditing) {
      return <BuilderComponent model="article" />
    }
    return (
      <Layout>
        <div className="bg-secondary">
          <div className="container py-5 mb-lg-3">
            <div className="row justify-content-center pt-lg-4 text-center">
              <div className="col-lg-5 col-md-7 col-sm-9">
                <h1 className="display-404 py-lg-3">404</h1>
                <h2 className="h3 mb-4">
                  Nous ne parvenons pas à trouver la page que vous recherchez.
                </h2>
                <p className="fs-md mb-4">
                  <u>Voici quelques liens utiles à la place :</u>
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <div className="row">
                  <div className="col-sm-4 mb-3">
                    <Link className="card h-100 border-0 shadow-sm" to="/">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-home text-primary h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Accueil</h5>
                            <span className="text-muted fs-ms">
                              Retourner à l'accueil
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <Link
                      className="card h-100 border-0 shadow-sm"
                      to="/products"
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-search text-success h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Boutique</h5>
                            <span className="text-muted fs-ms">
                              Voir nos articles
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <Link
                      className="card h-100 border-0 shadow-sm"
                      to="/#contact"
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-help text-info h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Aide &amp; Support</h5>
                            <span className="text-muted fs-ms">
                              Nous envoyer un message
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default p404Index
